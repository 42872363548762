<template>
  <v-tooltip v-if="file && typeof file == 'object' && !!file.url" bottom class="d-inline">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" color="primary" @click="newWindow(file.url)">
        mdi-file-document-outline
      </v-icon>
    </template>
    <span
      ><slot name="tooltip">{{ file.filename }}</slot></span
    >
  </v-tooltip>
</template>

<script>
export default {
  props: {
    file: {
      type: [Object],
      default: null
    }
  },
  methods: {
    newWindow(url) {
      var h = screen.height;
      var w = screen.width;
      window.open('', 'CPIDetail', `width=${h},height=${w}`).location = url;
    }
  }
};
</script>

<style>
</style>
